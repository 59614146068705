<template>
  <div v-if="timeAmount">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">Editing Time Amount: <small>{{ timeAmount.name }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"></danger-alert>

    <div class="form-group text-left">
      <label for="time_amount_name">Time Amount Name</label>
      <input type="text" class="form-control" id="time_amount_name" v-model="timeAmount.name" placeholder="Name">
    </div>

    <div class="form-group text-left">
      <label for="time_amount">Time Amount</label>
      <input type="number" step="0.1" class="form-control" id="time_amount" v-model="timeAmount.amount">
    </div>
    <div class="form-group text-left">
      <label for="time-amount-archived">Is Archived?</label>
      <b-checkbox id="time-amount-archived" v-model="timeAmount.is_archived"/>
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <router-link :to="{ name: 'TimeAmountShow', params: { id: this.$route.params.id } }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="updateTimeAmount" type="button" class="btn btn-success">Update Time Amount</button>
      </div>
    </div>

    <br>

  </div>
  <loading-overlay v-else></loading-overlay>
</template>
<script>
import { TimeAmountService } from '@/common/services/time_amount.service';
import DangerAlert from '@/common/components/danger-alert.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'LanguageEdit',
  components: {
    DangerAlert,
    LoadingOverlay,
  },
  data() {
    return {
      timeAmount: null,
      errors: [],
    };
  },
  created() {
    TimeAmountService.get(this.$route.params.id)
      .then(({ data }) => {
        this.timeAmount = data;
      });
  },
  methods: {
    updateTimeAmount() {
      TimeAmountService.update(this.$route.params.id, this.timeAmount)
        .then(() => {
          this.$router.push({ name: 'TimeAmountShow', params: { id: this.$route.params.id } });
        }).catch((err) => {
          // Scroll to the top of this view so user's can see errors
          window.scrollTo(0, document.getElementById('top').offsetTop);
          this.errors = err.response.data.error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/time_amounts";
</style>
